
const handleScrollForSandwich = () => {
  const elementTarget = document.querySelector('h1') || document.querySelector('h2');
  if (window.scrollY > (elementTarget.offsetTop + elementTarget.offsetHeight)) {
    sandwichElementMobile.classList.add('-fixed');
  } else if (window.scrollY < (elementTarget.offsetTop + elementTarget.offsetHeight) * 0.95) {
    sandwichElementMobile.classList.remove('-fixed');
  }
};


const handleCardToggle = ({target}) => {
  let btnElement = target;
  while (!btnElement.classList.contains('btn')) {
    btnElement = btnElement.parentNode;
  }

  const isElementClosed = btnElement.classList.contains('-closed');

  let parentElement = btnElement.parentNode;

  while (!parentElement.classList.contains('card')) {
    parentElement = parentElement.parentNode;
  }

  let btnText;
  if (isElementClosed) {
    btnElement.classList.remove('-closed');
    parentElement.classList.remove('-closed');
    btnText = 'show less';
  } else {
    btnElement.classList.add('-closed');
    parentElement.classList.add('-closed');
    btnText = 'show more';
  }

  btnElement.querySelector('.-text').innerHTML = btnText;
};


const handleInfoCardToggle = ({target}) => {
  let btnElement = target;
  while (!btnElement.classList.contains('-toggle')) {
    btnElement = btnElement.parentNode;
  }

  const isElementClosed = btnElement.classList.contains('-closed');

  let parentElement = btnElement.parentNode;

  while (!parentElement.classList.contains('card')) {
    parentElement = parentElement.parentNode;
  }

  if (isElementClosed) {
    btnElement.classList.remove('-closed');
    parentElement.classList.remove('-closed');
  } else {
    btnElement.classList.add('-closed');
    parentElement.classList.add('-closed');
  }
};


// Agenda day selection handler
const handleAgendaDaySelection = (currentDay) => {

  // Sets current day in control
  document.querySelectorAll('.agenda-section>.-controls>.-item').forEach(control => {
    control.dataset.agendaDay == currentDay ? control.classList.add('-current') : control.classList.remove('-current');
  });

  // Sets current day in title/description
  document.querySelectorAll('.agenda-section>.-day-title>h3,.agenda-section>.-day-title>h4').forEach(control => {
    control.dataset.agendaDay == currentDay ? control.classList.add('-visible') : control.classList.remove('-visible');
  });

  // Sets current day in cards
  document.querySelectorAll('.card.-agenda').forEach(control => {
    control.dataset.agendaDay == currentDay ? control.classList.add('-visible') : control.classList.remove('-visible');
  });
};

const agendaSwitchEventHandler = ({target}) => handleAgendaDaySelection(target.dataset.agendaDay);

// Get ICS File
const getIcalFile = ({target}) => {
  const thisCal = ics();

  const dataset = target.parentNode.dataset;

  const parsedDateStart = moment(dataset.dateStart);
  const parsedDateEnd = moment(dataset.dateEnd);

  

  thisCal.addEvent(dataset.title, dataset.description, 'Canvas', parsedDateStart.format(), parsedDateEnd.format());

  setTimeout(function() {
    thisCal.download();
  }, 100);
};


// Open/Close action modal
const openActionModal = ({target}) => {
  let parentElement = target;
  while(!parentElement?.parentNode.classList.contains('-actions')) {
    parentElement = parentElement.parentNode;
  }
  setTimeout(() => { parentElement.classList.toggle('-open'); }, 150);
  
};
const handleClickActionModal = ({target}) => {
  if(!target.parentNode.classList.contains('-actions')) {
    document.querySelector('.-share.-open')?.classList.remove('-open');
    document.querySelector('.-calendar.-open')?.classList.remove('-open');
  };
}



const sandwichElementMobile = document.querySelector('nav>.-container>.-logo-container>.-sandwich-container>.-circle-object');
const cardSwitcherElements = document.querySelectorAll('button.btn.btn-detail-switch');
const infoCardSwitcherElements = document.querySelectorAll('.-heading>div.-toggle');
const agendaDayControllerElements = document.querySelectorAll('.agenda-section>.-controls>.-item');
const actionBtns = document.querySelectorAll('div.-actions>.-share,.-calendar');
const icsDownloadBtns = document.querySelectorAll('button.-ics-download-call');

const initialize = () => {
  sandwichElementMobile?.addEventListener('click', () => document.querySelector('nav').classList.toggle('-open'));
  cardSwitcherElements?.forEach(element => element.addEventListener('click', handleCardToggle));
  infoCardSwitcherElements?.forEach(element => element.addEventListener('click', handleInfoCardToggle));
  agendaDayControllerElements?.forEach(element => element.addEventListener('click', agendaSwitchEventHandler));

  actionBtns?.forEach(element => element.addEventListener('click', openActionModal));
  icsDownloadBtns?.forEach(element => element.addEventListener('click', getIcalFile));
  window.addEventListener('click', handleClickActionModal);

  if(agendaDayControllerElements) {
    handleAgendaDaySelection(25);
  }

  window.addEventListener('scroll', handleScrollForSandwich);
};
document.addEventListener('DOMContentLoaded', initialize);



/**
 * Dismiss cookie notice tooltip
 */
/*
const dismissCookieTooltip = ({target}) => {
  document.querySelector('div.privacy-notice').classList.remove('-visible');
  document.cookie = 'cookies_accepted=accepted';
};

document.addEventListener('DOMContentLoaded', () => {

  const tooltipCookie = document.cookie
    .split('; ')
    .find(row => row.startsWith('cookies_accepted'))
    ?.split('=')[1];
  

  if(!tooltipCookie) {
    document.querySelector('div.privacy-notice').classList.add('-visible');
    document.querySelector('div.privacy-notice>button').addEventListener('click', dismissCookieTooltip);
  }

  const privacyLink = document.querySelector('.privacy_link_managed').getAttribute('href');
  const privacyPolicyLinks = document.querySelectorAll('a.privacy_termos');
  privacyPolicyLinks.forEach(node => {
    node.setAttribute('href', privacyLink);
  })
});
*/
